







































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import {
  ContactData,
  ResponseListMaster,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import {
  DataResponseGetListPurchaseOrder,
  RequestClosePurchaseOrder,
} from "@/models/interface/purchase.interface";
import { ListPurchaseRequisitionData } from "@/models/interface/purchaseRequisition.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import { masterServices } from "@/services/master.service";
import { purchaseServices } from "@/services/purchase.service";
import { purchaseRequisitionServices } from "@/services/purchaseRequisition.service";
import moment, { Moment } from "moment";
import { Component, Vue } from "vue-property-decorator";

interface LoadingComponent {
  loadingBranch: boolean;
  loadingFind: boolean;
  loadingSupplier: boolean;
  loadingPurchaseRequisitionNumber: boolean;
  loadingPurchaseOrderNumber: boolean;
  loadingPurchaseOrderType: boolean;
  loadingStatus: boolean;
  isFormSubmitted: boolean;
}

@Component
export default class PurchaseOrder extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "purchasingClosePeriod" });
  formModal = this.$form.createForm(this, { name: "modalClosePO" });
  visible = false;
  page = 1;
  limit = 10;
  totalData = 0;
  selectedRowKeys: number[] = [];
  params: RequestQueryParamsModel = {};
  loading: LoadingComponent = {
    loadingBranch: false,
    loadingFind: false,
    loadingSupplier: false,
    loadingPurchaseRequisitionNumber: false,
    loadingPurchaseOrderNumber: false,
    loadingPurchaseOrderType: false,
    loadingStatus: false,
    isFormSubmitted: false,
  };
  dataBranch: DataWarehouseBranch[] = [];
  dataSupplier: ContactData[] = [];
  dataPurchaseRequisitionNumber: ListPurchaseRequisitionData[] = [];
  dataPurchaseOrderNumber: DataResponseGetListPurchaseOrder[] = [];
  dataPurchaseOrderType: ResponseListMaster[] = [];
  dataSource: DataResponseGetListPurchaseOrder[] = [];
  dataStatus: ResponseListMaster[] = [];
  listId: string[] = [];
  filterForm = {
    date: null as Moment[] | null,
  };

  columnsTable = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      width: 110,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchWarehouseName",
      key: "branchWarehouseName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_purchase_order_type"),
      dataIndex: "poType",
      key: "poType",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_pr_number"),
      dataIndex: "purchaseRequisitionNo",
      key: "purchaseRequisitionNo",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_purchase_order_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_supplier_name"),
      dataIndex: "supplierName",
      key: "supplierName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "date",
      key: "date",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_status"),
      dataIndex: "status",
      key: "status",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
    },
  ];

  formRulesModal = {
    reasonToClosePo: {
      label: "lbl_reasonToClosePo",
      name: "reasonToClosePo",
      placeholder: "lbl_reasonToClosePo_placeholder",
      decorator: [
        "reasonToClosePo",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  formRules = {
    branch: {
      label: "lbl_branch",
      name: "branch",
      placeholder: "lbl_branch_placeholder",
      decorator: [
        "branch",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      label: "lbl_supplier_name",
      name: "supplierName",
      placeholder: "lbl_supplier_name_placeholder",
      decorator: [
        "supplierName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "lbl_date",
    },
    purchaseRequisitionNumber: {
      label: "lbl_purchase_requisition_number",
      name: "purchaseRequisitionNumber",
      placeholder: "lbl_purchase_requisition_number_placeholder",
      decorator: [
        "purchaseRequisitionNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderNumber: {
      label: "lbl_purchase_order_number",
      name: "purchaseOrderNumber",
      placeholder: "lbl_purchase_order_number_placeholder",
      decorator: [
        "purchaseOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    purchaseOrderType: {
      label: "lbl_purchase_order_type",
      name: "purchaseOrderType",
      placeholder: "lbl_purchase_order_type_placeholder",
      decorator: [
        "purchaseOrderType",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      label: "lbl_status",
      name: "status",
      placeholder: "lbl_status_placeholder",
      decorator: [
        "status",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };

  onSelectChange(value) {
    this.selectedRowKeys = value;
  }

  handleClosePO() {
    if (this.selectedRowKeys.length < 1) {
      this.$notification.error({
        message: "Error",
        description: "Select data first to close",
      });
    } else {
      this.visible = true;
    }
  }

  handleCancelModal() {
    this.visible = false;
  }

  submitForm() {
    this.formModal.validateFields((err, res) => {
      if (err) return;
      if (this.selectedRowKeys.length > 0) {
        this.loading.isFormSubmitted = true;
        const payload: RequestClosePurchaseOrder = {
          reasonToClose: res.reasonToClosePo,
        };
        this.listId = this.dataSource
          .map((dataMap, index) => {
            if (this.selectedRowKeys.includes(index)) return dataMap.id;
            else return "";
          })
          .filter(dataFilter => dataFilter !== "");
        purchaseServices
          .closePurchaseOrder(payload, this.listId.join(","))
          .then(response => {
            const listDocument: string[] = response.map(dataMap => {
              return dataMap.documentNumber;
            });
            this.$notification.success({
              message: "Success",
              description: `Document Number : ${listDocument.join(
                ","
              )} Success to close`,
            });
            this.visible = false;
            this.formModal.resetFields();
            this.handleFind(false);
          })
          .finally(() => (this.loading.isFormSubmitted = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Select data first to close",
        });
      }
    });
  }

  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.handleFind(false);
  }

  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.handleFind(false);
  }

  assignSearch(key, value, and): string {
    if (key === "branch" && value) return and + `branch.secureId~${value}`;
    else if (key === "supplierName" && value)
      return and + `supplier.secureId~${value}`;
    else if (key === "purchaseOrderNumber" && value)
      return and + `documentNumber~*${value}*`;
    else if (key === "purchaseRequisitionNumber" && value)
      return and + `purchaseRequisition.documentNo~*${value}*`;
    else if (key === "purchaseOrderType" && value)
      return and + `poType~${value}`;
    else if (key === "status" && value) return and + `status~${value}`;
    // jika key = status
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    if (this.filterForm.date && this.filterForm.date.length > 0) {
      search = `date>=${moment(this.filterForm.date[0])
        .set({ hour: 0, minute: 0, second: 0 })
        .utcOffset("+07")
        .format()}_AND_date<=${moment(this.filterForm.date[1])
        .set({ hour: 23, minute: 59, second: 59 })
        .utcOffset("+07")
        .format()}`;
    }
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  handleFind(firstSearch) {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
        sorts: "createdDate:desc",
      };
      if (firstSearch) params.page = 0;
      params.search = this.dynamicSearch(res);
      this.loading.loadingFind = true;
      purchaseServices
        .getListPurchaseOrder(params)
        .then(response => {
          this.totalData = response.totalElements;
          this.dataSource = response.data.map((dataMap, index) => {
            return {
              ...dataMap,
              no:
                response.currentPage === 0
                  ? index + 1
                  : this.limit * (this.page - 1) + index + 1,
              key: index,
              date: moment(dataMap.date).format(DEFAULT_DATE_FORMAT),
            };
          });
        })
        .finally(() => (this.loading.loadingFind = false));
    });
  }

  handleCreate() {
    this.$router.push("/purchasing/purchase-order/create");
  }

  responseViewTable(response) {
    this.$router.push(`/purchasing/purchase-order/detail/${response.data.id}`);
  }

  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }

  getSupplier(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true`,
    };
    if (valueSearch)
      params.search = `supplier~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_AND_supplier~true`;
    this.loading.loadingSupplier = true;
    contactServices
      .listContactData(params)
      .then(res => {
        this.dataSupplier = res.data;
      })
      .finally(() => (this.loading.loadingSupplier = false));
  }

  getPurchaseRequisitionNumber(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
    };
    if (valueSearch) params.search = `documentNo~*${valueSearch}*`;
    this.loading.loadingPurchaseRequisitionNumber = true;
    purchaseRequisitionServices
      .listPurchaseRequisition(params)
      .then(response => {
        this.dataPurchaseRequisitionNumber = response.data.filter(
          dataFilter => dataFilter.documentNo
        );
      });
    this.loading.loadingPurchaseRequisitionNumber = false;
  }

  getPurchaseOrderNumber(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
    };
    if (valueSearch) params.search = `documentNumber~*${valueSearch}*`;
    this.loading.loadingPurchaseOrderNumber = true;
    purchaseServices
      .getListPurchaseOrder(params)
      .then(response => {
        this.dataPurchaseOrderNumber = response.data.filter(
          dataFilter => dataFilter.documentNumber
        );
      })
      .finally(() => (this.loading.loadingPurchaseOrderNumber = false));
  }

  getPurchaseOrderType(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "PURCHASE_ORDER_TYPE",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingPurchaseOrderType = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataPurchaseOrderType = response;
      })
      .finally(() => (this.loading.loadingPurchaseOrderType = false));
  }

  getStatus(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      name: "PURCHASE_ORDER_STATUS",
    };
    if (valueSearch) params.search = `value~*${valueSearch}*`;
    this.loading.loadingStatus = true;
    masterServices
      .listMaster(params)
      .then(response => {
        this.dataStatus = response;
      })
      .finally(() => (this.loading.loadingStatus = false));
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getSupplier = debounceProcess(this.getSupplier, 400);
    this.getPurchaseRequisitionNumber = debounceProcess(
      this.getPurchaseRequisitionNumber,
      400
    );
    this.getPurchaseOrderNumber = debounceProcess(
      this.getPurchaseOrderNumber,
      400
    );
    this.getPurchaseOrderType = debounceProcess(this.getPurchaseOrderType, 400);
    this.getStatus = debounceProcess(this.getStatus, 400);
    this.getBranch("");
    this.getSupplier("");
    this.getPurchaseRequisitionNumber("");
    this.getPurchaseOrderNumber("");
    this.getPurchaseOrderType("");
    this.getStatus("");
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
