import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";
// Class
import { Api } from "@/models/class/api.class";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import {
  CreatePurchaseRequisitionIn,
  DetailPurchaseRequisition,
  ReponseListPurchaseRequisition,
} from "@/models/interface/purchaseRequisition.interface";

export class PurchaseRequisitionServices extends HttpClient {
  downloadPurchaseRequisition = (
    params: RequestQueryParamsModel
  ): Promise<Blob> => {
    return this.get<Blob>(Api.PurchaseRequisition + "/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  listPurchaseRequisition = (
    params: RequestQueryParamsModel
  ): Promise<ReponseListPurchaseRequisition> => {
    return this.get<ReponseListPurchaseRequisition>(Api.PurchaseRequisition, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  detailPurchaseRequisition = (
    id: string
  ): Promise<DetailPurchaseRequisition> => {
    return this.get<DetailPurchaseRequisition>(
      Api.PurchaseRequisition + `/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listStatusPurchaseRequisition = (): Promise<string[]> => {
    return this.get<string[]>(Api.StatusPurchaseRequisition)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  listRequestTypePurchaseRequisition = (): Promise<string[]> => {
    return this.get<string[]>(Api.RequestTypePurchaseRequisition)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  createPurchaseRequisition = (
    payload: CreatePurchaseRequisitionIn
  ): Promise<boolean> => {
    return this.post<boolean, CreatePurchaseRequisitionIn>(
      Api.PurchaseRequisition,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  approverPurchaseRequisition = (
    payload: CreatePurchaseRequisitionIn,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, CreatePurchaseRequisitionIn>(
      Api.ApproverPurchaseRequisition + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
  updatePurchaseRequisition = (
    payload: CreatePurchaseRequisitionIn,
    id: string
  ): Promise<boolean> => {
    return this.put<boolean, CreatePurchaseRequisitionIn>(
      Api.PurchaseRequisition + `/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  submitRequisition = (payload: any, id: string): Promise<any> => {
    return this.post<any, any>("" + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  cencelRequisition = (payload: any, id: string): Promise<any> => {
    return this.post<any, any>("" + `/${id}`, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}
export const purchaseRequisitionServices = new PurchaseRequisitionServices();
